body {
  font-size: 13px;
  line-height: 1.5;
  font-family: 'Rubik', sans-serif;
  background-color: #021321;
  background-image: url('../src/assets/body-bg.png');
  background-attachment: fixed;
  background-size: cover;
  
}
a {
  color: #e5f3f6;
  text-decoration: none;
  -webkit-transition: all .4s ease-out 0s;
  transition: all .4s ease-out 0s;
}

a:hover,
button:hover {
  color: #33D1EB !important;
  text-decoration: none;
  -webkit-transition: all .4s ease-out 0s;
  transition: all .4s ease-out 0s;
}

ul {
  margin: 0;
}

li {
  list-style: none;
}

body:before {
  display: none;
  content: "";
  background: url(../src/assets/bg-1.png) no-repeat 0 0 transparent;
  width: 60%;
  height: 100%;
  background-size: cover;
  right: 0;
  mix-blend-mode: screen;
  z-index: -1;
  position: fixed;
  animation: animation-02 19s infinite linear alternate;
  max-width: 1220px;
}


main:after {
  content: "";
  background: url(../src/assets/star-min.png) no-repeat 0 0 transparent;
  position: absolute;
  width: 50%;
  height: 300px;
  background-size: cover;
  z-index: -1;
  top: unset;
  right: 0;
  bottom: -35px;
  max-width: 800px;
  animation: animation-01 8s infinite cubic-bezier(0.59, 0.26, 0.48, 0.72) alternate;
  opacity: 0;

}


header {
  border-bottom: 1px solid #ffffff1f;
}

header .container-fluid {
  max-width: 1700px;
}

.offcanvas-menu {
  background: #070f25;
  max-width: 80%;
}

.offcanvas-menu:after {
  mix-blend-mode: screen;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(../src/assets/bg-1.png) no-repeat top center transparent;
  background-size: cover;
  top: 0;
  z-index: -1;
  opacity: 0.3;
}

.offcanvas-menu:before {
  background: url(../src/assets/body-bg.png) no-repeat 0 0 #021321;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.6;
}

.mobile-nav {
  display: block;
  margin-bottom: 2rem;
}

.mobile-nav li a {
  padding: 16px 0px;
  display: block;
  border-bottom: 1px solid #ffffff14;
}

.offcanvas-header {
  color: white;
}

.offcanvas-header h5 {
  font-weight: 500;
}

.offcanvas-body {
  padding: 0px 20px;
}

.close-button {
  background: transparent;
  color: #29fff1;
  font-size: 28px;
  height: 30px;
  line-height: 27px;
  opacity: .8;
  width: 30px;
  display: inline-block;
  position: absolute;
  right: 28px;
  border: 0;
}
.close-button i {
  margin: 0;
  padding: 0;
}
.close-button:hover {
  opacity: 1;
}

.switch-mode {
  width: 44px;
  height: 44px;
  border: 0;
  padding: 0;
  margin: 0px 20px;
  background: #1950ac;
  color: white;
  border-radius: 50%;
}

.switch-mode:hover {
  background: #0f45a1;
}

ul.main-nav {
  margin: 0;
  padding: 0;
}

.main-nav li a {
  position: relative;
  padding: 34px 1rem;
  font-size: 14px;
  color: white;
  display: block;
}

.main-nav li a:hover,
.main-nav li a.active {
  color: #33D1EB;
}

.main-nav li a:before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  height: 1px;
  left: 0;
  background-image: linear-gradient(to right, #080a1700 0%, #28b1be 51%, #00000000 100%);
  transition: 0.5s;
  right: 0;
  margin: 0px auto;
}

.main-nav li a.active:before {
  width: 80%;
  transition: 0.5s;
}

.button-primary {
  height: 44px;
  border: 2px solid #33D1EB;
  padding: 14px 2rem;
  border-radius: 36px;
  color: #33D1EB;
  font-weight: 500;
  line-height: 1;
}

.button-primary:hover {
  background: #1950ac;
  color: white !important;
  border-color: #1950ac;
}

.button-secondary {
  background: #2171d4;
  color: #ffffff;
  font-weight: 500;
  padding: 12px 0;
  border-radius: 36px;
  border: 2px solid transparent;
  text-align: center;
}

.button-secondary:hover {
  color: white !important;
  background: #185eb3;
}

.button-switch {
  background: #8c20b4;
  color: #ffffff;
  font-weight: 500;
  padding: 12px 0;
  border-radius: 36px;
  border: 2px solid transparent;
}

.button-switch:hover {
  background: #7c19a0;
  color: #ffffff !important;
}

.menu-control:before {
  content: "\e6c2";
  font-family: 'themify';
}

.menu-control {
  background: none;
  border: none;
  color: #09ebc5;
  font-size: 21px;
}

main {
  padding: 3rem 0;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.date-filter {
  background: #071A2A;
  padding: 0;
  margin: 0;
  border-radius: 36px;
}

.filter-title {
  color: white;
  line-height: 44px;
  padding: 0px 1.4rem;
  border-radius: 36px;
  font-size: 14px;
}

.filter-options {
  padding: 0;
}

.filter-options {
  justify-content: flex-start !important;
  display: flex !important;
  align-items: center !important;
}

.filter-options li button {
  background: transparent;
  color: white;
  border: 0;
  padding: 0px 12px;
  line-height: 44px;
  font-size: 15px;
}

.filter-options li button.active {
  color: #33D1EB;
}

.version-info {
  color: #BBD2E4;
}

.version-info li {
  font-size: 14px;
  align-items: center !important;
  display: flex;
}

.version-info li img,
.version-info li svg {
  margin-right: 6px;
}
.page-title {
  background: #061929c9;
  padding: 32px 26px;
  border-radius: 16px;
  color: white;
}

.page-title h1 {
  font-size: 28px;
  margin: 0;
}

.page-title p {
  color: #8ba3a3;
  margin: 0;
}
.title-icon {
  background: #571ada;
  border-radius: 9px;
  font-size: 26px;
  margin-right: 14px;
  min-width: 54px;
  padding: 8px 12px;
  text-align: center;
  height: 56px;
}
.card-style-1 {
  background: #061929c9;
  padding: 26px 26px 0px 26px;
  border-radius: 16px;
}

.card-title {
  color: #8ba3a3;
  font-size: 15px;
  font-weight: 400;
  margin: 0;
}

.card-value {
  font-size: 24px;
  color: white;
}

.sub-text {
  color: #33D1EB;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.card-right img {
  position: absolute;
  right: 20px;
  bottom: 0;
}

.card-style-2 {
  background: #061929c9;
  padding: 26px;
  border-radius: 16px;
}

.card-style-2 .card-value {
  font-size: 15px;
  color: #33D1EB;
}

.card-top {
  margin-bottom: 30px;
}

.chart img {
  max-width: 100%;
  height: auto;
}

.faq-accordion {
  background: transparent !important;
  counter-reset: accordioncounter;
}

.faq-accordion .accordion-item {
  background: #061929c9;
  color: white;
  font-size: 14px;
  line-height: 1.8;
  font-weight: 300;
  border: 0;
  margin-bottom: 15px;
}

.faq-accordion .accordion-item .accordion-body {
  border: 0;
  padding: 0px 20px 20px 20px;
}

.faq-accordion .accordion-header button {
  background: transparent;
  color: #0fd9ff;
  padding: 24px 52px 24px 20px;
  border: 0;
  box-shadow: none;
  font-size: 17px;
}

.faq-accordion .accordion-header button:after {
  content: "\f078";
  font-family: 'FontAwesome';
  background: no-repeat;
  width: 24px;
  height: 24px;
  position: absolute;
  text-align: center;
  right: 20px;
  line-height: 24px;
}

.faq-accordion .accordion-header button:before {
  counter-increment: accordioncounter;
  content: counters(accordioncounter, ".") ".";
  margin: 0 1rem;
  color: #fff;
}

/* Governance */
.proposal {
  background: #061929c9;
  padding: 2.6rem 2rem;
  border-radius: 16px;
  color: white;
  position: relative;
}
.proposal-results {
  margin-top: 2rem;
}
.proposal .proposal-header h2 {
  font-size: 24px;
  margin-bottom:0;
}
.proposal .proposal-header h2 a {
  color: white;
}
.proposal .proposal-timestamp {
  color: #8ba3a3;
}
.proposal-timestamp:before {
  font-family: "Font Awesome 6 Pro";
  margin-right: 6px;
  content: "\f274";
  color: #33d1eb;
}
.proposal .proposal-status {
  background: #c0147d;
  padding: 6px 18px;
  border-radius: 36px;
  font-size: 12px;
  font-weight: 500;
}

.proposal .proposal-header {
  position: relative;
  margin-bottom: 1rem;
  align-items: flex-start;
  justify-content: space-between!important;
  display: flex!important;
}

.proposal .proposal-summary {
  color: #8ba3a3;
  font-size: 15px;
  max-width: 600px;
}

.proposal-results .progress {
  margin-bottom: 1.6rem;
  height: 42px;
  border-radius: 36px;
  background: #0d1f31;
  position: relative;
}
.proposal-results .progress:last-child {
  margin: 0;
}
.proposal-results .progress-bar {
  flex-direction: row;
  align-items: center!important;
  background: #4b14c0 !important;
  justify-content: flex-start!important;
  border-radius: 36px;
  overflow: visible;
}
.proposal-options {
  position: absolute;
  left: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 95%;
  text-align: left;
}
.proposal-results .progress-bar span:first-child {
  margin-right: 20px;
}
.proposal-results .progress-bar i {
  margin-right: 10px;
}

/* Governance */

/* Charts */
.chart-right canvas {
  max-width: 100px;
}
/* Charts */
@media (max-width: 1180px) {
  header {
    padding: 20px 0;
  }
}

@media (min-width:1366px) {
  .container {
    max-width: 1240px;
  }
}

@media (min-width:1400px) {
  .container {
    max-width: 1320px;
  }
}

@media (min-width: 960px) {
  .date-filter {
    align-items: center !important;
    justify-content: flex-start !important;
    display: flex !important;
  }

  .filter-title {
    background: #9614C4;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
  .card-style-1 {
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 1024px) and (max-width: 768px) and (orientation: portrait) {
  .card-style-1 {
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .card-style-1 {
    margin-bottom: 1rem;
  }
}

@media (min-width: 1024px) and (max-width: 1365px) {
  body {
    background-size: cover;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  header .container-fluid {
    padding: 0px 26px;
  }
}

@media (max-width: 1024px) {
  header {
    padding: 20px 0;
  }
}

@media (max-width: 860px) {
  .version-info li {
    font-size: 13px;
  }

  body:before {
    width: 140%;
  }
  .proposal .proposal-header h2 {
    font-size: 22px;
    margin-bottom: 0;
}
  .card-style-1,
  .card-style-2 {
    margin-bottom: 1rem;
  }

  .date-filter {
    background: transparent;
  }

  .filter-title {
    padding: 0px 10px;
    display: none;
  }

  .filter-options {
    padding-left: 10px;
  }

  .filter-options {
    background: #061526;
    border-radius: 16px;
  }

  .filter-options li button {
    font-size: 14px;
  }

}

@media (max-width: 520px) {
  body {
    background-position: 0 0 !important;
  }
}
@media (max-width: 390px) {
  .filter-options li button {
    padding: 0 10px;
  }
}
